import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC, ReactElement } from "react";
import React, { useMemo } from "react";
import type { Paragraph } from "src/constants/readMoreContent";
import HtmlParser from "src/lib/HtmlParser";
import Translate from "src/ui/components/Translate/Translate";

interface Props {
  title: string;
  description?: ReactElement | string;
  fullPackageInsertLink?: string;
  mainContent?: Paragraph[] | React.ReactElement;
  onClose: (event?: React.MouseEvent) => void;
  classNameWrap?: string;
  classNameInner?: string;
  image?: React.JSX.Element | string;
  centerHeader?: boolean;
  additionalFixedContent?: () => ReactElement;
}

const ProfileImage = styled.div`
  display: inline-block;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const SanityContent: FC<Props> = ({
  title,
  description,
  fullPackageInsertLink,
  mainContent,
  classNameWrap,
  classNameInner,
  additionalFixedContent,
  image
}) => {
  const renderDescription = useMemo(() => {
    if (!description) return null;

    if (typeof description === "string") {
      const content = new HtmlParser(description);

      return <h5>{content.toJsx()}</h5>;
    }

    return <h4>{description}</h4>;
  }, [description]);

  const renderImage = useMemo(() => {
    if (!image) return null;

    if (typeof image === "string") {
      return <ProfileImage style={{ backgroundImage: `url(${image})` }} />;
    }

    return image;
  }, [image]);

  const renderParagraphContent = (
    content?: React.JSX.Element | string[] | string
  ): React.JSX.Element => {
    if (!content) return <></>;

    if (typeof content === "string") {
      return <div>{new HtmlParser(content).toJsx()}</div>;
    }

    if (Array.isArray(content)) {
      return (
        <div>
          <ul>
            {content.map((item, itemIndex) => (
              <li key={`item-${itemIndex}`}>{new HtmlParser(item).toJsx()}</li>
            ))}
          </ul>
        </div>
      );
    }

    return content;
  };

  const renderMainContent = useMemo(() => {
    if (!mainContent) return null;

    if (Array.isArray(mainContent))
      return mainContent.map(({ title: paragraphTitle, content }, index) => (
        <div key={index}>
          {paragraphTitle && <h5>{new HtmlParser(paragraphTitle).toJsx()}</h5>}
          {renderParagraphContent(content)}
        </div>
      ));

    return mainContent;
  }, [mainContent]);

  return (
    <div className={clsx(classNameWrap)}>
      <div className={clsx(classNameInner)}>
        <div>
          <div className={clsx({})}>
            <h3>{title}</h3>
            {renderDescription}
            <nine-spacer s="sm"></nine-spacer>
            {renderImage}
          </div>

          {renderMainContent}
        </div>

        <div>
          {additionalFixedContent?.()}

          {fullPackageInsertLink && (
            <>
              <nine-spacer s="sm"></nine-spacer>
              <nine-button
                href={fullPackageInsertLink}
                variant="outline"
                target="_blank"
              >
                <Translate msg="read_full_package_insert" />
              </nine-button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default SanityContent;
